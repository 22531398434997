import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { baseUrl, data, headers } from './config/config';
import WelcomePage from './components/WelcomePage';
import Home from './components/Home';
import React,{useContext, useEffect,useState} from "react";
import HostedPage from './components/HostedPage';
import ContestWelcome from './components/ContestWelcome';
import Error from './components/Error'
import Quiz from './components/Quiz';
import Scoreboard from './components/Scoreboard';
import Unsub from './components/Unsub';
import SubPage from './components/SubPage';
import GoToSub from './components/GoToSub'
import { Context } from './Context/Context';
import Modal from './components/Modal';
import Destination from './components/section/Destination';
import Kitchen from './components/section/Kitchen';
import TourGuide from './components/section/TourGuide';
import Culture from './components/section/Culture';
import UnlockQuestions from './components/UnlockQuestions';
import ModalNew from './components/ModalNew';
import SubModal from './components/SubModal';
import axios from 'axios';
import Lang from './components/Lang';
import Gallery from './components/Gallery';
import UnsubModal from './components/unsubFailModal'
import UnlockModal from './components/UnlockModal'
import Congrats from './components/CongratsModal'
import QuizModal from './components/QuizModal'
import UnlockFail from './components/UnlockFailModal'
import DreamTravel from './components/ThemePage';
import UnsubSuccess from './components/UnsubSuccess';
import Leaderboard from './components/Leaderboard';

function App() {
  const [apiData,setApiData] = useState([]);
  const [introPageData, setIntroPageData] = useState();
  const [loader, setLoader] = useState(true);
  const {isModal , handlePageLoader,  isNewModal , isSubModal,setAppLang} = useContext(Context);
  const lang = localStorage.getItem("appLang") ? localStorage.getItem("appLang") : "";
  useEffect(()=>{
     if(!lang){
      localStorage.setItem("appLang","en")
      setAppLang('en')
     }
  },[])

  useEffect(() => {
      getConfig();
      // getAvailCont();
  },[])
  // post api for apiConfi
  const getConfig = async () => {
    handlePageLoader(true)
      const response = await axios({
          method: 'post',
          url: baseUrl+'appConfig',
          headers:headers,
      })
      setApiData(response.data)
      setIntroPageData(response.data?.intro_page?.[0]);
      localStorage.setItem("thumbUrl" , response?.data?.resources?.thumbURL)
      handlePageLoader(false)
      setLoader(false);
  }
if(window.location.pathname==='/lp')
{
 return  <Router>
  <Routes>
   <Route exact path="/lp" element={<Leaderboard />} />
  </Routes>
  </Router>
}
  return (
    <div className="App">
      {isModal && <Modal />}
      {isNewModal && <ModalNew />}
      {isSubModal && <SubModal />}
      <Router>
        <Routes>
          <Route exact path='/welcome' element={<WelcomePage baseUrl={baseUrl} apiData ={apiData} loader={loader} introPageData={introPageData} headers={headers} data={data} />} />
          <Route exact path='/home' element={<Home baseUrl={baseUrl} configData ={apiData}   headers={headers} data={data} />} />
          <Route exact path="/menuList/:something" element={<HostedPage/>} />
          <Route exact path="/navlist/:something" element={<HostedPage/>} />
          <Route exact path="/contest" element={<ContestWelcome baseUrl={baseUrl} configData ={apiData} headers={headers} data={data} />} />
          <Route exact path="/quiz" element={<Quiz baseUrl={baseUrl} configData ={apiData} headers={headers} data={data} />} />
          <Route exact path="/scoreboard" element={<Scoreboard />} />
          <Route exact path="/unsub" element={<Unsub />} />
          <Route exact path="/sub" element={<GoToSub />} />
          <Route exact path="/sub" element={<SubPage/>} />
          <Route exact path="/destination" element={<Destination />} />
          <Route exact path="/kitchen" element={<Kitchen />} />
          <Route exact path="/tourguide" element={<TourGuide />} />
          <Route exact path="/culture" element={<Culture />} />
          <Route exact path="/unlock" element={<UnlockQuestions />} />
          <Route exact path="/lang" element={<Lang/>} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/error" element={<Error />} />
          <Route exact path="/unsubfail" element={<UnsubModal />} />
          <Route exact path="/unsubsuccess" element={<UnsubSuccess />} />
          <Route exact path="/unlockmodal" element={<UnlockModal />} />
          <Route exact path="/congrats" element={<Congrats />} />
          <Route exact path="/quizmodal" element={<QuizModal />} />
          <Route exact path="/unlockfail" element={<UnlockFail />} />
          <Route exact path="/" element={<DreamTravel />} />
          {/* <Route exact path="/unlockStatus" element={<GeneralModal/>}/> */}
        </Routes>
      </Router>
    </div>
  );
}
export default App;
